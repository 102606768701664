import {
  Heading,
  Box,
  Image,
  Text,
  Input,
  Button,
  SimpleGrid,
  Checkbox,
  InputRightElement,
  InputGroup,
  FormControl,
} from "@chakra-ui/react";
import { React, useEffect, useState } from "react";
import banner from "../assets/banner.jpeg";
import { getEndpoint, getOptions } from "../constants/api";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export default function Home() {
  const [password, setPassword] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadOverlay, setLoadOverlay] = useState(false);
  const [loginRes, setLoginRes] = useState({});
  const [accept, setAccept] = useState(false);

  const [force, setForce] = useState(false);
  const handleClick = () => setShow(!show);
  const navigate = useNavigate();

  let loginButton = force ? (
    <Button
      colorScheme="red"
      margin="3px 0"
      onClick={() => {
        forceLogin();
      }}
    >
      Force
    </Button>
  ) : (
    <Button
      colorScheme="red"
      margin="3px 0"
      onClick={() => {
        onLogin();
      }}
    >
      Submit
    </Button>
  );

  const onLogin = async () => {
    if (password && accept) {
      setIsLoading(true);
      setLoadOverlay(true);
      let packet = {
        ip: uuidv4(),
        password: password,
        device: deviceName,
      };

      const options = getOptions(packet);
      const endpoint = getEndpoint("login");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();
        setLoginRes(data);
        setIsLoading(false);
        setLoadOverlay(false);
      } catch (error) {
        alert(error);
      }
    } else {
      if (!password) {
        alert("Put in your password first");
      } else if (!accept) {
        alert("Accept terms and conditions");
      }
    }
    setLoadOverlay(false);
  };

  const loginValidation = async () => {
    if (loginRes.status === "valid") {
      let packet = {
        ip: uuidv4(),
        password: password,
        device: deviceName,
      };

      const options = getOptions(packet);
      const endpoint = getEndpoint("forcelogin");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();
      } catch (error) {
        alert(error);
      }

      sessionStorage.setItem("pack", JSON.stringify(packet));

      return navigate("/live");
    } else if (loginRes.status === "already in") {
      setForce(true);
      alert(
        `status: already logged in\ndevice: ${loginRes.device}\nif IP has changed, force login`
      );
    } else if (loginRes.status === "Invalid Password") {
      alert("Wrong Password. Make sure no spaces");
    }
  };

  const forceLogin = async () => {
    let packet = {
      ip: uuidv4(),
      password: password,
      device: deviceName,
    };

    const options = getOptions(packet);
    const endpoint = getEndpoint("forcelogin");

    try {
      const res = await fetch(endpoint, options);
      const data = await res.json();
    } catch (error) {
      alert(error);
    }

    sessionStorage.setItem("pack", JSON.stringify(packet));

    return navigate("/live");
  };

  useEffect(() => {
    if (!isLoading) {
      loginValidation();
    }
  }, [isLoading]);

  let loading;

  if (loadOverlay) {
    loading = (
      <Box
        position="fixed"
        zIndex="999"
        left="0"
        top="0"
        width="100%"
        height="100%"
        overflow="auto"
        backgroundColor="rgba(0,0,0,0.4)"
      >
        <Text
          textAlign="center"
          verticalAlign="middle"
          lineHeight="100vh"
          fontWeight="bold"
        >
          Loading ..
        </Text>
      </Box>
    );
  }

  return (
    <Box textAlign="center" color="white">
      <SimpleGrid columns="2" minChildWidth="340px">
        <Image
          src={banner}
          alt="Program Banner"
          width="100%"
          margin="auto"
          padding="10px"
        />
        <Box
          padding="10px"
          backgroundColor="#424242"
          width="70%"
          margin="0 auto"
          borderRadius="md"
          textAlign="left"
        >
          <Heading
            textTransform="uppercase"
            as="h2"
            size="xl"
            color="white"
            textAlign="center"
          >
            For Viewing Centers Only
          </Heading>
          <Input
            type="text"
            placeholder="Username"
            margin="5px 0"
            readOnly={force}
          />
          <InputGroup size="md">
            <Input
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="Enter password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              readOnly={force}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick} color="black">
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Text color="red" fontSize="x-small">
            00000000-0000-0000-0000-00000000000 format
          </Text>
          <Box padding="10px 0">
            <Text>Device Name</Text>
            <Input
              type="text"
              placeholder="Remember Device Name"
              margin="5px 0"
              readOnly={force}
              onChange={(e) => {
                setDeviceName(e.target.value);
              }}
            />
            <Text color="red" fontSize="x-small">
              It's important you set a name to help remember logged in device
            </Text>
          </Box>
          <Box color="white">
            <Checkbox
              padding="5px"
              checked={accept}
              onChange={(e) => {
                setAccept(e.target.checked);
              }}
            />
            <Text display="inline">
              By logging in, you confirm that you accept to not share your login
              details with a third party and that the ministry retains full and
              exclusive copyright ownership of all segments of this service
              hence no segment of the broadcast should be recorded, copied,
              re-transmitted in any form or by any means, stored in any form of
              electronic retrieval system and used for any other purpose than
              that which it was intended.
            </Text>
          </Box>
          {loginButton}
        </Box>
      </SimpleGrid>
    </Box>
  );
}
