import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Input,
  Box,
  Button,
  Text,
  Heading,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { getEndpoint, getOptions } from "../constants/api";

export default function AdminDashboard() {
  const [viewCentres, setViewCentres] = useState([
    { name: "", _id: "", participants: "" },
  ]);
  const [individuals, setIndividuals] = useState([
    { name: "", _id: "", participants: "" },
  ]);
  const [newName, setNewName] = useState("");

  function newCentre(name) {
    let center = {
      name: name,
      password: uuidv4(),
      participants: 0,
      remember: "",
      ip: "",
    };

    newCenter(center);
    getAllCentres();
  }

  const newCenter = async (data) => {
    const options = getOptions(data);
    const endpoint = getEndpoint("add/viewcenter");

    try {
      const res = await fetch(endpoint, options);
      const data = await res.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCentres = async () => {
    const endpoint = getEndpoint("centres");

    try {
      const res = await fetch(endpoint);
      console.log(res);
      const info = await res.json();
      setViewCentres(info);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllIndividuals = async () => {
    const endpoint = getEndpoint("indi/individuals");

    try {
      const res = await fetch(endpoint);
      const info = await res.json();
      setIndividuals(info);
    } catch (error) {
      console.log(error);
    }
  };

  const resetLogins = async () => {
    const endpoint = getEndpoint("indi/reset");

    try {
      const res = await fetch(endpoint);
      const info = await res.json();
      alert(info);
    } catch (error) {
      console.log(error);
    }
  };

  function downloadObjectAsJson(exportObj, exportName) {
    var dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  useEffect(() => {
    getAllCentres();
    getAllIndividuals();
  }, []);

  return (
    <>
      <Box textAlign="center">
        <Heading textTransform="uppercase" as="h2" size="xl">
          Viewing Centres
        </Heading>
        <Button
          colorScheme="red"
          margin="auto"
          onClick={() => {
            getAllCentres();
          }}
        >
          Refresh
        </Button>
      </Box>
      <Table variant="simple">
        <TableCaption>Viewing Centers</TableCaption>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Password</Th>
            <Th>Device</Th>
            <Th>IP</Th>
            <Th textAlign="center">Remove Center</Th>
            <Th isNumeric>No of participants</Th>
          </Tr>
        </Thead>
        <Tbody>
          {viewCentres.map((center) => {
            return (
              <Tr>
                <Td>{center.name}</Td>
                <Td>{center._id}</Td>
                <Td>{center.remember}</Td>
                <Td>{center.ip}</Td>
                <Td textAlign="center">
                  <Button colorScheme="red" margin="auto">
                    Delete
                  </Button>
                </Td>
                <Td isNumeric>{center.participants}</Td>
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Name</Th>
            <Th>Password</Th>
            <Th>Device</Th>
            <Th>IP</Th>
            <Th textAlign="center">Remove Center</Th>
            <Th isNumeric>No of participants</Th>
          </Tr>
        </Tfoot>
      </Table>
      <Box>
        <Text margin="3px">Add new center:</Text>
        <Input
          placeholder="Name of Center"
          margin="3px"
          onChange={(e) => {
            setNewName(e.target.value);
          }}
        />
        <Button
          colorScheme="blue"
          margin="3px"
          onClick={() => {
            newCentre(newName);
          }}
        >
          Submit
        </Button>
        <Button
          colorScheme="blue"
          margin="3px"
          onClick={() => {
            downloadObjectAsJson(viewCentres, "centres");
          }}
        >
          Download Data
        </Button>
      </Box>
      <Box textAlign="center">
        <Heading textTransform="uppercase" as="h2" size="xl">
          Individuals
        </Heading>
        <Button
          colorScheme="red"
          margin="auto"
          onClick={() => {
            getAllIndividuals();
          }}
        >
          Refresh
        </Button>
        <Button
          colorScheme="blue"
          margin="3px"
          onClick={() => {
            downloadObjectAsJson(individuals, "individuals");
          }}
        >
          Download Data
        </Button>
      </Box>
      <Table variant="simple">
        <TableCaption>Individuals</TableCaption>
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Name</Th>
            <Th>Church</Th>
            <Th>Device</Th>
            <Th>IP</Th>
            <Th>Password</Th>
          </Tr>
        </Thead>
        <Tbody>
          {individuals.map((individual) => {
            return (
              <Tr>
                <Td>{individual.title}</Td>
                <Td>{individual.name}</Td>
                <Td>{individual.church}</Td>
                <Td>{individual.remember}</Td>
                <Td>{individual.ip}</Td>
                <Td>{individual._id}</Td>
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Title</Th>
            <Th>Name</Th>
            <Th>Church</Th>
            <Th>Device</Th>
            <Th>IP</Th>
            <Th>Password</Th>
          </Tr>
        </Tfoot>
      </Table>
      <Box textAlign="center">
        <Button
          colorScheme="red"
          margin="auto"
          onClick={() => {
            resetLogins();
          }}
        >
          Reset Logs
        </Button>
      </Box>
    </>
  );
}
