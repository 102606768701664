import {
  SimpleGrid,
  Box,
  Image,
  Text,
  Link,
  VStack,
  Container,
  Stack,
} from "@chakra-ui/react";
import ReactHlsPlayer from "react-hls-player";
import kingspay from "../assets/kingspay.png";
import interac from "../assets/interac.png";
import paypal from "../assets/paypal.png";
import tithely from "../assets/tithely.jpeg";
import offering from "../assets/oferring-banner.jpeg";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { getEndpoint } from "../constants/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CatchAll from "./CatchAll";

export default function StreamPage() {
  const [valid, setValid] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const MILISECOND = 300000;
  const navigate = useNavigate();

  const checkDevice = async () => {
    if (sessionStorage.getItem("pack")) {
      let pack = JSON.parse(sessionStorage.getItem("pack"));
      const endpoint = getEndpoint(`checkdevice/${pack.ip}/${pack.password}`);

      try {
        const res = await fetch(endpoint);
        const data = await res.json();
        if (data === "valid") {
          setValid(true);
          setIsLoaded(true);
          console.log(valid);
        } else if (data === "invalid") {
          setValid(false);
          setIsLoaded(true);
          console.log(valid);
        }
      } catch (error) {
        alert(error);
      }
    } else if (sessionStorage.getItem("pack") === null) {
      setIsLoaded(true);
      return navigate("/live");
    }
  };

  useEffect(() => {
    checkDevice();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      checkDevice();
      console.log("checked");
    }, MILISECOND);

    return () => clearInterval(interval);
  }, []);

  if (!valid && isLoaded) {
    alert("Please login");
    return navigate("/live");
  }

  return (
    <Box backgroundColor="#4fbcd3">
      <SimpleGrid columns={2} minChildWidth="400px">
        <Container maxW="90%" padding="2%">
          <Stack
            spacing={{ base: 2 }}
            bg={"gray.50"}
            rounded={"xl"}
            p={{ base: 4 }}
            boxShadow="md"
            textAlign={"center"}
          >
            <Text fontWeight="500" fontSize="18px">
              Welcome
            </Text>
            <Text fontWeight="300" fontSize="16px">
            The Glory of His Prolific Presence
            </Text>
            {/* <ReactHlsPlayer
              // src="https://cdnstack.internetmultimediaonline.org/auxano/cecanada1/playlist.m3u8" 
              src="https://cdn3.wowza.com/1/RUkwWUJ6cXlwNFNE/c1lZNmx4/hls/live/playlist.m3u8"
              autoPlay={true}
              controls={true}
              width="100%"
              height="auto"
            /> */}
            <CatchAll/>
          </Stack>
        </Container>
        {/* <Box textAlign="center" margin="0 5px" border="1px solid red" borderRadius="md" padding="6px">
                    <Heading as="h3" size="lg">Live Comments</Heading>
                </Box> */}
      </SimpleGrid>
      <Box
        backgroundColor="#4fbcd3"
        padding="5px"
        margin="5px 0"
        textAlign="center"
        borderRadius="sm"
      >
        <Image
          src={offering}
          alt="Offering Banner"
          maxW="80vw"
          margin="auto"
          padding="20px 0"
        />
        <SimpleGrid columns={2} minChildWidth="240px">
          <VStack>
            <Box>
              <Image
                src={kingspay}
                alt="Kingspay Icon"
                maxW="150px"
                margin="auto"
              />
              <Text display="inline">
                {" "}
                Kingspay:{" "}
                <Text fontWeight="bold" color="white" display="inline">
                  CECAN
                </Text>{" "}
                (KINDLY ADD YOUR FULL NAME AS A REFERENCE){" "}
              </Text>
            </Box>
            <Box>
              <Image
                src={interac}
                alt="Interac Icon"
                maxW="150px"
                margin="auto"
              />
              <Text display="inline">
                {" "}
                EMT: Tithes, Offerings, Seeds:
                <Text fontWeight="bold" color="white" display="inline">
                  {" "}
                  offering@ceccar360.com
                </Text>
                , Partnership:
                <Text fontWeight="bold" color="white" display="inline">
                  {" "}
                  partnership@ceccar360.com{" "}
                </Text>
              </Text>
            </Box>
          </VStack>
          <VStack spacing={10}>
            <Box>
              <Image
                src={paypal}
                alt="Paypal Icon"
                maxW="150px"
                margin="auto"
              />
              <Text display="inline">
                {" "}
                To give with PayPal:
                <Link
                  padding="0 2px"
                  href="https://www.paypal.com/donate/?token=O46A7eAzb-ehvtoSPPzYJ-h2KuTtXYvyGlKqQOtLKRKmz8IWRCvoiL1pcbLPYhwnK-X44JlzVj3Crxk5"
                  isExternal
                  color="white"
                >
                  Click Here <ExternalLinkIcon mx="2px" />
                </Link>
              </Text>
            </Box>
            <Box>
              <Image
                src={tithely}
                alt="Tithely Icon"
                maxW="150px"
                margin="auto"
              />
              <Text display="inline">
                {" "}
                To give with Tithely:
                <Link
                  padding="0 2px"
                  href="https://tithe.ly/give?c=67419"
                  isExternal
                  color="white"
                >
                  Click Here <ExternalLinkIcon mx="2px" />
                </Link>
              </Text>
            </Box>
          </VStack>
        </SimpleGrid>
      </Box>
    </Box>
  );
}
