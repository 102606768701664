import React, { useEffect, useState } from "react"
import {Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, Box, Button, Heading} from "@chakra-ui/react"
import { getEndpoint } from "../constants/api"

export default function Monitor (){
    const [individuals, setIndividuals] = useState([{name: "", _id:'', participants:''}])
    const [list, setList] = useState([])


    const getAllIndividuals = async () => {
        const endpoint = getEndpoint("indi/individuals");
    
        try{
          const res = await fetch(endpoint)
          const info = await res.json()
          setIndividuals(info)
    
        }catch(error){
          console.log(error)
        }

        checkIn()
    }

    const resetLogins = async () => {
        const endpoint = getEndpoint("indi/reset");
    
        try{
          const res = await fetch(endpoint)
          const info = await res.json()
          alert(info)
    
        }catch(error){
          console.log(error)
        }
    }


    function downloadObjectAsJson(exportObj, exportObj2,  exportName, exportName2){
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();

        var dataStr2 = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj2));
        var downloadAnchorNode2 = document.createElement('a');
        downloadAnchorNode2.setAttribute("href",     dataStr2);
        downloadAnchorNode2.setAttribute("download", exportName2 + ".json");
        document.body.appendChild(downloadAnchorNode2); // required for firefox
        downloadAnchorNode2.click();
        downloadAnchorNode2.remove();
    }

    useEffect(() => {
        getAllIndividuals()
    }, [])

    const checkIn = () =>{
        let noLog = individuals.filter((individual)=> individual.ip === "")
        setList(noLog)
        
        if (list.length > 0){
            console.log(list.length)
        }
    }
    


    return(
        <>
            <Box textAlign="center">
                <Heading textTransform="uppercase" as="h2" size="xl">Individuals</Heading>
                <Button colorScheme="red"  margin="auto" onClick={()=>{getAllIndividuals()}}>Refresh</Button>
                <Button colorScheme="blue" margin="3px" onClick={()=>{downloadObjectAsJson(individuals, list,"individuals", "no-login")}}>Download Data</Button>
            </Box>

            <Table variant="simple">
                <TableCaption>Individuals</TableCaption>
                <Thead>
                    <Tr>
                        <Th>Title</Th>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Church</Th>
                        <Th>Device</Th>
                        <Th>IP</Th>
                        <Th>Password</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {individuals.map((individual)=>{
                        return(
                            <Tr>
                                <Td>{individual.title}</Td>
                                <Td>{individual.name}</Td>
                                <Td>{individual.email}</Td>
                                <Td>{individual.church}</Td>
                                <Td>{individual.remember}</Td>
                                <Td>{individual.ip}</Td>
                                <Td>{individual._id}</Td>
                            </Tr>
                        )
                    })}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Th>Title</Th>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Church</Th>
                        <Th>Device</Th>
                        <Th>IP</Th>
                        <Th>Password</Th>
                    </Tr>
                </Tfoot>
            </Table>
            <Table>
                <TableCaption>Not Logged</TableCaption>
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Email</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {list.map((individual) => 
                            <Tr>
                                <Td>{individual.name}</Td>
                                <Td>{individual.email}</Td>
                            </Tr>
                        )}
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Email</Th>
                        </Tr>
                    </Tfoot>
                </Table>
            <Box textAlign="center">
                <Button colorScheme="red"  margin="auto" onClick={()=>{resetLogins()}}>Reset Logs</Button>
            </Box>
        </>
    )
}