import ReactHlsPlayer from 'react-hls-player';

export default function CatchAll(){


    return(
        <ReactHlsPlayer
            // src="https://cdnstack.internetmultimediaonline.org/auxano/cecanada1/playlist.m3u8"
            src="https://cdn3.wowza.com/1/RUkwWUJ6cXlwNFNE/c1lZNmx4/hls/live/playlist.m3u8"
            autoPlay={true}
            controls={true}
            width="100%"
            height="auto"
        />
//         <iframe width="100%" height="auto" src="https://www.youtube.com/embed/VipsIPW7Q0I?si=ZPXU9ACe2aKqtkj0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpo
// licy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    )
}