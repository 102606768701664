import { Box } from "@chakra-ui/react";
import React from "react";
import Paths from "./Routes";

export default function App() {
  return (
    <Box>
      <Paths />
    </Box>
  );
}
