import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminDashboard from "./components/AdminDashboard";
import CatchAll from "./components/CatchAll";
import AtendeeTGOHP from "./components/CheckIn";
import Home from "./components/Home";
import Landing from "./components/Landing";
import LoginInd from "./components/LoginInd";
import UploadAtendees from "./components/MassUpload";
import Monitor from "./components/Monitor";
import OFTPStream from "./components/OFTPStream";
import StreamInd from "./components/StreamInd";
import StreamPage from "./components/StreamPage";

// Import the Components

export default function Paths() {
  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route exact path="/login" element={<Home />} />
      <Route exact path="/admin" element={<AdminDashboard />} />
      <Route exact path="/monitor" element={<Monitor />} />
      <Route exact path="/live" element={<StreamPage />} />
      <Route exact path="/catch-all" element={<CatchAll />} />
      <Route exact path="/login-ind" element={<LoginInd />} />
      <Route exact path="/live-ind" element={<StreamInd />} />
      <Route exact path="/rro" element={<OFTPStream name="Highly Esteemed Reverend Ray Sir" />} />
      <Route exact path="/ptt" element={<OFTPStream name="Highly Esteemed Pastor TT Sir" />} />
      <Route exact path="/evang" element={<OFTPStream name="Highly Esteemed Evang Sir" />} />
      <Route exact path="/pai" element={<OFTPStream name="Highly Esteemed Pastor Ambrose Sir" />} />
      <Route exact path="/poo" element={<OFTPStream name="Highly Esteemed Pastor Ose Ma" />} />
      <Route exact path="/oftrp-1" element={<OFTPStream name="OFTRP" />} />
      <Route exact path="/rko" element={<OFTPStream name="Highly Esteemed Reverend Ken Sir" />} />
      <Route exact path="/rogers" element={<OFTPStream name="Most Reverend Tom Sir" />} />
      <Route exact path="/media-1" element={<OFTPStream name="Media 1" />} />
      <Route exact path="/media-2" element={<OFTPStream name="Media 2" />} />
      <Route
        exact
        path="/view-cen"
        element={<OFTPStream name="Viewing Center" />}
      />
    </Routes>
  );
}
