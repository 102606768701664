import { Heading, Box, Image, Button } from "@chakra-ui/react";
import { React } from "react";
import banner from "../assets/banner.jpeg";
import { Link } from "react-router-dom";

export default function Landing() {
  return (
    <Box textAlign="center" color="red">
      <Heading textTransform="uppercase" as="h1" size="4xl">
        CE Canada Programs
      </Heading>
      <Heading textTransform="uppercase" as="h2" size="xl">
        The Glory of His Prolific Presence
      </Heading>
      <Image
        src={banner}
        alt="Program Banner"
        maxW="60vw"
        minW="280px"
        margin="auto"
      />
      <Button
        margin="10px"
        padding="15px"
        fontSize="20px"
        border="solid 4px red"
        color="black"
        borderRadius="none"
      >
        <Link to="/login">Viewing Center</Link>
      </Button>
      {/* <Button margin="10px" padding="15px" fontSize="20px" border="solid 4px red" color="black" borderRadius="none"><Link to="/login-ind">Individual</Link></Button> */}
    </Box>
  );
}
