import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "Bebas Neue",
  },
  colors: {
    project: {
      backgroundDark: "#CEDEFF",
      theme: "#C53030",
      theme50: "#e07e7e",
      light: "#e48f8f",
      theme100: "#a82929",
      background: "#F9F9F9",
      card: "#FFFFFF",
    },
  },
});
export default theme;
